import { useNuxtApp } from "#app";
import { defineStore } from "pinia";
import { API_LOADING_TIMEOUT } from "~/config/constant";
import {
  TYPE_LIVE_WIN,
  PROVIDER_TYPE_FILTER,
  CHALLENGE_TYPE,
} from "~/config/enum";
import {
  getGameCategories,
  getGameList,
  updateGameLike,
  getFavoriteUserGame,
  getListLiveWins,
  getRecentlyPlayed,
  getAllProviders,
  getMostGameWins,
  getProvidersByType,
  getGamesByProvider,
  getGameCategoriesForProvider,
  getChallengesByType,
} from "~/service/gameService";
import {
  gameLikeRequest,
  gameProvider,
  IGameCategories,
  IMostGameWins,
  listCategoryGame,
  listFilterParams,
  resCategoryGameList,
} from "~/types/schema";

export const useGameStore = defineStore("useGameStore", {
  state: () => ({
    categories: [] as IGameCategories[],
    listGameByCategory: {} as { [key: string]: listCategoryGame },
    listGameWinLive: [] as any,
    listGameWinWeek: [],
    listGameWinDay: [],
    listProviders: [] as gameProvider[],
    listGameWinByAmount: [] as IMostGameWins[],
    listGameByProvider: [] as any,
    listCategoriesForProvider: [] as any,
    loadingUpdateProvider: false,
    isLoadingGameWinByAmount: false,
    isLoadingPlayAgain: false,
    isLoadingFavorite: false,
  }),
  getters: {
    getCategories: (state) => state.categories,
    getListGameByCategory: (state) => state.listGameByCategory,
    getListGameBySlug: (state) => {
      return (slug: string) => state.listGameByCategory[slug].listGame;
    },
    getListGameWinLive: (state) => state.listGameWinLive,
    getListGameWinWeek: (state) => state.listGameWinWeek,
    getListGameWinDay: (state) => state.listGameWinDay,
  },
  actions: {
    async getCategoriesGame() {
      try {
        const res = await getGameCategories();
        if (res.data.success)
          this.categories = res.data.data.categories || res.data.data;
      } catch (e: any) {}
    },
    async getGameByCategory(
      slug: string,
      page = 1,
      getAll?: boolean,
      keyword?: string,
      providers?: Array<string>
    ) {
      try {
        // const slugReq = slug=='all'?'':slug;
        // const pageReq = slug=='all'?'':page;

        let res;

        const params: listFilterParams = {
          category_slug: slug == "all" ? "" : slug,
          page,
          keyword,
          providers,
        };

        if (getAll) {
          res = await getGameList(params);
          this.listGameByCategory[slug] = {
            slug: slug,
            total: res.data.total,
            listGame: {
              pageLoaded: page,
              games: res.data.data,
            },
          };
        } else {
          if (_has(this.listGameByCategory, slug)) {
            const pageLoaded =
              this.listGameByCategory[slug].listGame.pageLoaded;
            if (page > 1) {
              res = await getGameList(params);
              this.listGameByCategory[slug].listGame.pageLoaded = page;
              this.listGameByCategory[slug].listGame.games = [
                ...this.listGameByCategory[slug].listGame.games,
                ...res.data.data,
              ];
              return res.data.data;
            } else {
              res = await getGameList(params);
              this.listGameByCategory[slug] = {
                slug: slug,
                total: res.data.total,
                listGame: {
                  pageLoaded: page,
                  games: res.data.data,
                },
              };
              return res.data.data;
            }
          } else {
            res = await getGameList(params);
            this.listGameByCategory[slug] = {
              slug: slug,
              total: res.data.total,
              listGame: {
                pageLoaded: page,
                games: res.data.data,
              },
            };
          }
        }
        return res.data.data;
      } catch (e: any) {
        return [];
      }
    },
    async getGameFavorite({
      page = 1,
      keyword,
      providers,
      reverse,
    }: {
      page: number;
      keyword?: string;
      providers?: Array<string>;
      reverse?: boolean;
    }) {
      const params = {
        page,
        keyword,
        providers,
      };
      try {
        this.isLoadingFavorite = true;
        const res = await getFavoriteUserGame(params);
        const currentGameList =
          this.listGameByCategory["favorite"]?.listGame?.games || [];

        if (page > 1) {
          this.listGameByCategory["favorite"] = {
            slug: "favorite",
            listGame: {
              pageLoaded: page,
              games: [...currentGameList, ...res.data.data],
              gameOrginal: [...currentGameList, ...res.data.data],
            },
            total: res.data.total,
          };
        } else {
          const listData = reverse
            ? res?.data?.data?.reverse()
            : res?.data?.data;
          this.listGameByCategory["favorite"] = {
            slug: "favorite",
            listGame: {
              pageLoaded: page,
              games: listData,
              gameOrginal: listData,
            },
            total: res.data.total,
          };
        }

        return this.listGameByCategory["favorite"].listGame.games;
      } catch (e: any) {
        return [];
      }
    },
    async likeGame(slug: string, gameCode: string, favorite: boolean) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const req: gameLikeRequest = {
        game_code: gameCode,
        is_favorite: favorite,
      };
      try {
        await updateGameLike(req);

        //handle active/inactive favorite icon for current category of the game
        const listGame = this.listGameByCategory[slug].listGame.games;
        const indexGame = listGame.findIndex(
          (game: resCategoryGameList) => game.code == gameCode
        );
        this.listGameByCategory[slug].listGame.games[indexGame].is_favorite =
          favorite;

        //handle active/inactive favorite icon for other category that these game belong to (except current category clicked)
        for (let slugCategory in this.listGameByCategory) {
          if (slugCategory == slug || slugCategory == "favorite") continue;

          const listGameAll =
            this.listGameByCategory[slugCategory].listGame.games;
          const indexGameAll = listGameAll.findIndex(
            (game: resCategoryGameList) => game.code == gameCode
          );
          if (indexGameAll < 0) continue;
          this.listGameByCategory[slugCategory].listGame.games[
            indexGameAll
          ].is_favorite = favorite;
        }

        //handle append to favorite list
        if (favorite) {
          const gameClone = {
            ...this.listGameByCategory[slug].listGame.games[indexGame],
          };
          this.listGameByCategory["favorite"].listGame.games.unshift(gameClone);
        } else {
          const indexRemove = this.listGameByCategory[
            "favorite"
          ].listGame.games.findIndex((game: resCategoryGameList) => {
            return game.code == gameCode;
          });

          this.listGameByCategory["favorite"].listGame.games.splice(
            indexRemove,
            1
          );
        }
      } catch (e: any) {
      } finally {
        loader.hide();
      }

      return true;
    },
    async likeGamePlayGame(game_code: string, is_favorite: boolean) {
      const loader = (useNuxtApp() as any).$loading.show(
        useNuxtApp().$configLoading
      );

      const req: gameLikeRequest = { game_code: game_code, is_favorite };
      try {
        const res = await updateGameLike(req);
        return res?.data?.success;
      } catch (e: any) {
      } finally {
        loader.hide();
      }

      return false;
    },
    async listLiveWins() {
      const liveListGame = await getListLiveWins(TYPE_LIVE_WIN.LIVE);
      const weekList = await getListLiveWins(TYPE_LIVE_WIN.WEEK);
      const dayList = await getListLiveWins(TYPE_LIVE_WIN.DAY);

      this.listGameWinLive = liveListGame.data.data;
      this.listGameWinWeek = weekList.data.data;
      this.listGameWinDay = dayList.data.data;
    },
    async updateListLiveWins() {
      const liveListGame = await getListLiveWins(TYPE_LIVE_WIN.LIVE);

      liveListGame.data.data
        .slice()
        .reverse()
        .forEach((liveGameNew: any) => {
          const checkExist = this.listGameWinLive.filter((liveGameOld: any) => {
            return liveGameOld.transaction_id == liveGameNew.transaction_id;
          });

          if (!checkExist.length) this.listGameWinLive.unshift(liveGameNew);

          //pop data when list > 30
          const MAX_LENGTH = 30;
          if (this.listGameWinLive.length > MAX_LENGTH)
            this.listGameWinLive.pop();
        });
    },
    async getPlayAgain(page = 1, keyword?: string, providers?: Array<string>) {
      try {
        const params = {
          page,
          keyword,
          providers,
        };
        const res = await getRecentlyPlayed(params);
        const currentGameList =
          this.listGameByCategory["play-again"]?.listGame?.games || [];
        if (page > 1) {
          this.listGameByCategory["play-again"] = {
            slug: "play-again",
            listGame: {
              pageLoaded: page,
              games: [...currentGameList, ...res.data.data.data],
            },
            total: res.data.data.total,
          };
        } else {
          this.listGameByCategory["play-again"] = {
            slug: "play-again",
            listGame: {
              pageLoaded: page,
              games: res.data.data.data,
            },
            total: res.data.data.total,
          };
        }

        return this.listGameByCategory["play-again"].listGame.games;
      } catch (e) {
        return [];
      }
      return [];
    },
    async getListProviders() {
      try {
        const res = await getAllProviders();
        if (res.data.success) {
          this.listProviders = res.data.data.filter(
            (item: gameProvider) => !item.is_blocked
          );
        }
      } catch (e: any) {}
    },
    async getListGameByAmount() {
      try {
        this.isLoadingGameWinByAmount = true;
        const res = await getMostGameWins();
        if (res.data.success) {
          this.listGameWinByAmount = res.data.data.map(
            (item: any, index: number) => ({
              ...item,
              level_image: `/images/game-number/${index + 1}.png`,
            })
          );
        }
      } catch (e: any) {
      } finally {
        this.isLoadingGameWinByAmount = false;
      }
    },
    async getListProvidersByType(
      type: PROVIDER_TYPE_FILTER,
      params?: listFilterParams
    ) {
      try {
        this.loadingUpdateProvider = true;
        const res = await getProvidersByType(type, params);
        if (res.success) {
          this.listProviders = this.listProviders.map((item: gameProvider) => {
            const match = res.data?.find(
              (ele: any) => item.provider === ele?.provider
            );
            return {
              ...item,
              number_games: match?.number_games || 0,
            };
          });
        }
      } catch (e: any) {
      } finally {
        this.loadingUpdateProvider = false;
      }
    },
    async getListGameByProvider(params?: listFilterParams) {
      try {
        const res = await getGamesByProvider(params);
        if (res.data.success) {
          this.listGameByProvider = res?.data?.data;
          this.listGameByCategory["game-by-provider"] = {
            slug: "game-by-provider",
            listGame: {
              pageLoaded: Number(params?.page),
              games: this.listGameByProvider || [],
            },
            total: this.listGameByProvider?.length,
          };
        }
      } catch (e: any) {}
    },
    async getListCategoriesForProvider(params?: listFilterParams) {
      try {
        const res = await getGameCategoriesForProvider(params);
        if (res.data.success) {
          this.listCategoriesForProvider = res.data.data.map((item) => {
            return {
              ...item,
              number_games: item?.games_count || 0,
            };
          });
        }
      } catch (e: any) {}
    },
    async getListChallengesByType({
      type,
      page = 1,
      slug,
    }: {
      type: CHALLENGE_TYPE;
      page?: number;
      slug: string;
    }) {
      try {
        const params = {
          page,
        };
        const res = await getChallengesByType(type, params);
        const currentGameList =
          this.listGameByCategory[slug]?.listGame?.games || [];
        const computedData = res?.data?.data?.data?.map((item) => {
          return {
            ...item?.game,
            min_bet: item?.min_bet,
            min_multiplier: item?.min_multiplier,
            highest_achievement: item?.highest_achievement,
            promotion_amount: item?.promotion_amount,
            promotion_results_count: item?.promotion_results_count,
            challenge_achieved: item?.challenge_achieved,
            provider: "",
            provider_information: null,
          };
        });
        if (page > 1) {
          this.listGameByCategory[slug] = {
            slug: slug,
            listGame: {
              pageLoaded: page,
              games: [...currentGameList, ...computedData],
            },
            total: res.data.data.total,
          };
        } else {
          this.listGameByCategory[slug] = {
            slug: slug,
            listGame: {
              pageLoaded: page,
              games: computedData,
            },
            total: res.data.data.total,
          };
        }
        return this.listGameByCategory[slug].listGame.games;
      } catch (e: any) {
        return [];
      }
    },
    setGamesByCategory(slug: string, games: any[]) {
      if (!this.listGameByCategory[slug]) {
        this.listGameByCategory[slug] = { listGame: { games: [] } };
      }
      this.listGameByCategory[slug].listGame.games = games;
    },
  },
});
